import React from 'react';

const ClientSignup = React.lazy(()=>import('./views/ClientSignup/ClientSignup'));
const LawyerSignup = React.lazy(()=>import('./views/LawyerSignup/LawyerSignup'));
const Login = React.lazy(()=>import('./views/login/login'));
const Home = React.lazy(()=>import('./views/Home/Home'));
const ClientHome = React.lazy(()=>import('./views/ClientHome/ClientHome'));
const MileStones = React.lazy(()=>import('./views/MileStones/MileStones'));



const routes =[
    { path: '/Login', exact: true, name: 'Login', component: Login },
    // { path: '/ClientSignup', exact: true, name: 'Client Signup', component: ClientSignup },
    // { path: '/LawyerSignup', exact: true, name: 'Lawyer Signup', component: LawyerSignup },
    { path: '/:id', exact: true, name: 'Home', component: Home },
    { path: '/:id/Case/:caseID', exact: true, name: 'Case', component: MileStones },
    { path: '/CHome', exact: true, name: 'CHome', component: ClientHome },

];

export default routes;
