import React, { useEffect, Suspense } from 'react'
import AppContent from '../AppContent'
import { useDispatch, useSelector } from 'react-redux'
import WaitingPage from './WaitingPage'
import PlansPage from './PlansPage'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import PlansFormPage from './PlansFormPage'

const DefaultLayout = () => {
    const currentUser = useSelector((state) => state.currentUser)
    console.log(currentUser.user.type);
    console.log("type");
    return (
        <div>
            {
                currentUser.user.type === "lawyer" ?
                    currentUser.user.current_companies.length == 0
                        ?
                        <PlansFormPage />
                        :
                        <AppContent />
                :
                <AppContent />
            }
        </div>
    )
}

export default DefaultLayout
