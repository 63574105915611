import React, { memo } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const SingleCustomAutocomplete = props => {
    const filedName = props.filedName;
    const fieldLabel = props.label;
    const list = props.list;
    const fieldValue = props.value;
    const listKey = props.listKey;
    const listDescription = props.description;
    const customOnChange = props.customOnChange;

    const optionForLabel = (label) => {
        if (typeof label === 'object' &&
            !Array.isArray(label) &&
            label !== null) {
            return label[listDescription]
        }
        let toReturn = list.find(
            (source) => { return source[listKey] == label }
        )
        if (toReturn) {
            return toReturn[listDescription]
        }
        return `${label}`
    }

    const getOptionSelected = (option, value) => {
        if (typeof option === 'object' &&
            !Array.isArray(option) &&
            option !== null) {
            return option[listKey] == value
        }
        return false
    }

    const update = (event, value) => {
        if (!customOnChange) {
            return;
        }
        if (value === null) {
            customOnChange(null, filedName, "", event, value);
            return
            // }else if( typeof value === 'string'){
            //     setValue(optionForLabel(value))
        } else {
            customOnChange(value[listKey], filedName, value[listDescription], event, value);
        }
    }

    const theme = createTheme({
        components: {
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '30px',
                            border: 'none',
                            backgroundColor:'#F4F4F4',
                            '& input': {
                                color: '#66023C',
                                // direction: 'rtl',
                                // textAlign: 'right',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border:'none'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                // borderColor: '#66023C',
                                border:'none'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#66023C',
                                border:'none'
                            },
                            '& .MuiAutocomplete-popupIndicator': {
                                color: '#66023C',
                              },
                        },
                    },
                    popper: {
                        '& .MuiAutocomplete-option': {
                            color: '#66023C',
                            // direction: 'rtl',
                            // textAlign: 'right',
                        },
                    },
                },
            },
            MuiTextField: { // Add this section to override TextField styles
                styleOverrides: {
                    root: {
                        '& label': {
                            color:'#66023C',
                            fontWeight:'bold',
                        },
                        '& label.Mui-focused': {
                            color: '#66023C',
                            // display:'none'
                        },
                    },
                },
            },
        },
    });

    const CustomAutocomplete = styled(Autocomplete)({});
    
    return (
        <FormControl style={{ width: "100%",marginTop: 10, marginBottom: 10 }}>
            <ThemeProvider theme={theme}>
                <Autocomplete
                    disabled={props.disabled ? props.disabled : false}
                    name={filedName}
                    options={list} // Options List
                    value={fieldValue}
                    onChange={update}
                    getOptionLabel={optionForLabel}
                    getOptionSelected={getOptionSelected}
                    //   isOptionEqualToValue={getOptionSelected}
                    style={{ width: "100%" }}
                    renderInput={(params) => <TextField {...params}
                        label={fieldLabel}
                    />}
                />
            </ThemeProvider>
        </FormControl>
    );
}

SingleCustomAutocomplete.propTypes = {
    filedName: PropTypes.any.isRequired,
    label: PropTypes.any.isRequired,
    list: PropTypes.any.isRequired,
    value: PropTypes.any,
    listKey: PropTypes.any.isRequired,
    description: PropTypes.any.isRequired,
    customOnChange: PropTypes.any,
    disabled: PropTypes.any,
}

export default SingleCustomAutocomplete;
