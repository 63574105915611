import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import routes from '../route'
import { CircularProgress } from '@mui/material'
const AppContent = () => {
    return (
        <Suspense fallback={<CircularProgress color="primary" />}>
            <Routes>
                {routes.map((route, idx) => {
                    return (
                        route.component && (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                element={<route.component />}
                            />
                        )
                    )
                })
                }
            </Routes>
        </Suspense>
    )
}
export default AppContent