import { Grid } from '@mui/material';
import React, { useState } from 'react'
import Frame from '../../images/frame.svg';
import styles from '../../styles/login.module.css';
import { CustomInput } from '../../components/Custom/CustomInputForms';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomButton from '../../components/Custom/CustomButton';
import Google from '../../images/google.png';
import Apple from '../../images/apple.png';
import CustomDialog from '../../components/Custom/CustomDialog';
import SignupForm from './SignupForm';
import handelErrors from '../../components/helpers/handelErrors';
import axios from 'axios';
import { useDispatch } from 'react-redux'
import cookie from 'js-cookie'
import allActions from '../../actions/allActions';
import { useTranslation } from "react-i18next";
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword } from "firebase/auth";
import App from '../../FireBase/App';
import { useNavigate } from 'react-router-dom';

const provider = new GoogleAuthProvider();
const auth = getAuth();


const Login = () => {
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [open, setOpen] = useState({
        status: false,
        withAuth: false,
    });
    const [user, setUser] = useState({});
    const [inputType, setInputType] = useState(true);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const logIn = () => {
        setSaveIsLoading(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
            data: formData,
        })
            .then((res) => {
                signInWithEmailAndPassword(auth, formData.email, formData.password)
                    .then((result) => {
                        localStorage.setItem('google_email', result.user.email);
                        localStorage.setItem('google_password', result.user.uid);
                        localStorage.setItem('google_uid', result.user.uid);
                        dispatch(allActions.userActions.setUser(res.data.user))
                        cookie.set("token", res.data.access_token);
                        axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.access_token}`;
                        setSaveIsLoading(false)
                        let current_companies = res.data.user.current_companies;
                        let user_type = res.data.user.type;
                        if (user_type === "lawyer") {
                            if (current_companies.length > 0) {
                                let id = current_companies[0].id;
                                const targetElement = current_companies.find(e => e.id === id)
                                localStorage.setItem('vat', targetElement.vat);

                                navigate(`/${id}`);
                                localStorage.setItem("permittedCompanies", JSON.stringify(res.data.user.current_companies));
                            } else {
                                localStorage.setItem("permittedCompanies", null);
                                navigate('/CompleteSetup');
                            }
                        } else {
                            navigate('/CHome');
                        }
                    }).catch((error) => {
                        setSaveIsLoading(false)
                        handelErrors(error);
                        console.log(error);
                    });

            })
            .catch((error) => {
                setSaveIsLoading(false)
                handelErrors(error);
            });
    }
    const checkEmail = (user) => {
        const email = user.email;
        const password = user.uid;
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/checkEmail`, {
            params: {
                email: email,
                password: password,
            }
        })
            .then(res => {
                if (res.data.exist == true) {
                    dispatch(allActions.userActions.setUser(res.data.response.original.user))
                    cookie.set("token", res.data.response.original.access_token);
                    axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.response.original.access_token}`;
                } else {
                    setOpen({
                        status: true,
                        withAuth: true,
                    });
                    localStorage.setItem('google_email', email);
                    localStorage.setItem('google_password', password);
                    localStorage.setItem('google_uid', password);
                }
            }).catch(err => {
                handelErrors(err);
            })
    }
    const logInWithGoogle = async () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                const user = result.user;
                setUser(user);
                checkEmail(user);
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.customData.email;
                const credential = GoogleAuthProvider.credentialFromError(error);
                console.log(error);
            });
    }
    const handleInputChange = (event) => {
        const { id, value, checked, type } = event.target;
        if (type == "checkbox") {
            setFormData({
                ...formData,
                [id]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: value,
            });
        }
    }
    const signUp = () => {
        setOpen({
            status: true,
            withAuth: false
        });
    }
    const handleClose = () => {
        setOpen(false);
    }

    const handleChangeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    return (
        <Grid container style={{ backgroundColor: '#F4F4F4' }}>
            <Grid item md={7} xs={12} >
                <div style={{
                    display: 'flex'
                    , justifyContent: 'center'
                    , alignItems: 'center'
                    , height: '100%'
                    , width: '100%'
                }}>
                    <div style={{
                        height: '98%'
                        , width: '70%'
                        , backgroundColor: '#ffffff'
                        , display: 'flex'
                        , justifyContent: 'center'
                        , alignItems: 'center'
                    }}>
                        <div className={styles.container}>
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={Frame} />
                            </div>
                            <div className={styles.text} style={{ marginTop: '10px' }}>
                                Log In
                            </div>
                            <Grid container style={{ marginTop: '20px' }}>
                                <Grid item xs={12}>
                                    <CustomInput
                                        id={'email'}
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        placeholder='Email Address'
                                        withBorder={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomInput
                                        id={'password'}
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        placeholder='Password'
                                        type={inputType == true ? 'password' : 'text'}
                                        withBorder={false}
                                        endAdornment={
                                            <VisibilityIcon style={{ cursor: 'pointer', color: '#650E3D' }}
                                                onClick={() => setInputType(!inputType)} />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <div style={{ color: '#650E3D', fontSize: '14px' }}>
                                Forgot Password? <a href="#" style={{ color: '#650E3D' }}>Reset Now</a>
                            </div>
                            <Grid container style={{ marginTop: '20px' }}>
                                <Grid item xs={12}>
                                    <CustomButton variant="contained" title="Log In" onClick={logIn} isLoading={saveIsLoading} />
                                </Grid>
                            </Grid>
                            <div className={styles.line_container} style={{ marginTop: '40px' }}>
                                <div className={styles.left_line}></div>
                                <div className={styles.centered_text}>Don't Have an Account?</div>
                                <div className={styles.right_line}></div>
                            </div>
                            <Grid container style={{ marginTop: '20px' }}>
                                <Grid item xs={12}>
                                    <CustomButton variant="outlined" title="Sign Up" onClick={signUp} />
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: '20px' }}>
                                <Grid item xs={12}>
                                    <CustomButton variant="outlined"
                                        title="Sign In With Google"
                                        onClick={logInWithGoogle}
                                        isLoading={saveIsLoading}
                                        startIcon={
                                            <img src={Google} style={{ position: 'relative', left: '-3px' }} />
                                        } />
                                </Grid>
                            </Grid>
                            <Grid container style={{ marginTop: '20px' }}>
                                <Grid item xs={12}>
                                    <CustomButton
                                        variant="outlined"
                                        title="Sign In With Apple"
                                        onClick={logIn}
                                        isLoading={saveIsLoading}
                                        style={{
                                            backgroundColor: 'black',
                                            width: '100%',
                                            borderColor: 'black',
                                            borderRadius: '30px',
                                            height: '50px',
                                            color: 'white'
                                        }}
                                        startIcon={
                                            <img src={Apple} style={{ position: 'relative', left: '-3px' }} />
                                        } />
                                </Grid>
                            </Grid>
                        </div>

                    </div>
                </div>

            </Grid>
            <Grid item md={5} sm={0}>
                <div style={{
                    minHeight: '100vh', backgroundColor: '#66023C'
                }}>
                    {/* <button onClick={() => { handleChangeLanguage("ar") }}>AR</button>
                    <button onClick={() => { handleChangeLanguage("en") }}>En</button> */}
                </div>
            </Grid>
            <SignupForm open={open.status} withAuth={open.withAuth} onClose={handleClose} />
        </Grid>
    );
}
export default Login