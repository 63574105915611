import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";

const CustomButton = (props) => {

    const styleFilled = {
        backgroundColor: '#650E3D',
        width: '100%',
        borderColor: '#650E3D',
        borderRadius: '30px',
        height: '50px'
    }
    const styleOutlined = {
        color: '#650E3D',
        width: '100%',
        border: '2px solid #650E3D',
        borderRadius: '30px',
        height: '50px',
        fontWeight:'bold'
    }

    const style = props.variant == "contained" ? styleFilled : styleOutlined;
    const variant = props.variant =="contained" ? "contained" : "outlined";

    return (
        <Button
            variant={variant}
            className={props.className ? props.className : ''}
            disabled={props.isLoading ? true : false}
            // style={props.style}
            style={props.style ? props.style : style}
            onClick={props.onClick}
            startIcon={props.startIcon ? props.startIcon : null}
            endIcon={props.endIcon ? props.endIcon : null}
        >
            {props.isLoading && <CircularProgress size="sm" />} {props.title}
        </Button>
    )
}

export default CustomButton
