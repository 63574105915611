import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@mui/material';
import Frame from 'src/images/frame.svg'
import { useTranslation } from 'react-i18next';
import { CustomToggle } from '../Custom/CustomInputForms';
import CustomCardPlan from '../Custom/CustomCardPlan';
import axios from 'axios';
import handelErrors from '../helpers/handelErrors';
import CreateCompany from './CreateCompany';
import Swal from 'sweetalert2';
import cookie from 'js-cookie'
import { useDispatch } from 'react-redux'
import allActions from 'src/actions/allActions';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const PlansFormPage = () => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const [plansList, setPlansList] = useState([]);
    const navigate = useNavigate();

    const [selectedPlanType, setSelectedPlanType] = useState('monthly');
    const [selectedPlanID, setSelectedPlanID] = useState('');
    const [selectedPlan, setSelectedPlan] = useState([]);


    useEffect(() => {
        if (plansList.length == 0) {
            const getPlans = () => {
                axios.get(`${process.env.REACT_APP_API_BASE_URL}/masterData/plans/get`, {
                    params: {},
                })
                    .then(function (response) {
                        setPlansList(response.data);
                    })
                    .catch(function (error) {
                        handelErrors(error)
                    })
            }
            getPlans();
        }

    }, []);

    const handleSelect = (e) => {
        setSelectedPlanType(e.target.value);
    }

    const handleCreate = (id) => {
        setSelectedPlanID(id);
        plansList.map((e) => {
            if (e.id == id) {
                setSelectedPlan(e);
            }
        });
        setOpen(true);
    }
    const logout = () => {
        setOpen(false);
        axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/auth/logout`)
            .then((res) => {
                axios.defaults.headers.common['Authorization'] = ``
                localStorage.clear()
                cookie.remove('token')
                dispatch(allActions.userActions.logOut())
                // window.location.reload()
                navigate('/');
            })
            .catch((e) => {
                toast.error('Error');
            })
    }
    const handleCloseCreate = (refresh,id,companies) => {
        setOpen(false);
        if (refresh) {
            if(companies.length > 0){
                const targetElement = companies.find(e => e.id === id)
                localStorage.setItem('vat',targetElement.vat);
                localStorage.setItem("permittedCompanies",JSON.stringify(companies));
                navigate(`/${id}`);
            }else{
                localStorage.setItem("permittedCompanies",null);
                navigate('/CompleteSetup');
            }
            window.location.reload()
            // logout();
        }
    }

    return (
        <div style={{ minHeight: '100vh', backgroundColor: '#F4F4F4' }}>
            <div style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px' }}>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={12} marginTop={'40px'}>
                        <img src={Frame} width={40} />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', marginTop: '50px', marginBottom: '30px' }} className='d-flex justify-content-center align-items-center'>
                            <CustomToggle
                                onChange={handleSelect}
                                value={selectedPlanType}
                                options={
                                    [
                                        {
                                            id: 'monthly', label: `${t('monthly')}`
                                        },
                                        {
                                            id: 'yearly', label: `${t('yearly')}`
                                        },
                                    ]
                                }
                            />
                        </div>
                    </Grid>
                    {
                        plansList.map((e, key) => (
                            <Grid item xs={12} md={4} key={key}>
                                <CustomCardPlan data={e} type={selectedPlanType} onClick={handleCreate} />
                            </Grid>
                        ))
                    }
                </Grid>
                <CreateCompany open={open}
                    onClose={handleCloseCreate}
                    selectedPlan={selectedPlan}
                    selectedPlanType={selectedPlanType}
                />
            </div>
        </div>
    )
}
export default PlansFormPage